<template>
	<Modal :options="{width: '90vw'}">
		<div class="title">Detalles Garantia</div>
		<div class="body">
			<ul class="nav nav-tabs mb-4">
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 1 ? ' active' : '')" @click="tabs_info = 1">Fideicomiso</span></li>
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 2 ? ' active' : '')" @click="tabs_info = 2">Partes</span></li>
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 3 ? ' active' : '')" @click="tabs_info = 3">Dirección</span></li>
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 4 ? ' active' : '')" @click="tabs_info = 4">Detalles</span></li>
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 5 ? ' active' : '')" @click="tabs_info = 5">Habitaciones, servicios y amenidades</span></li>
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 6 ? ' active' : '')" @click="tabs_info = 6">Imágenes</span></li>
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 7 ? ' active' : '')" @click="tabs_info = 7">Mensajes</span></li>
			</ul>

			<fieldset v-show="tabs_info == 1">
				<legend>Fideicomiso</legend>
				<div class="row form-group">
					<label for="fideicomiso.no_fideicomiso" class="col-form-label col-sm-2">Número de fideicomiso</label>
					<div class="col-sm-4"><input v-model="garantia.fideicomiso.no_fideicomiso" type="text" name="fideicomiso.no_fideicomiso" id="fideicomiso.no_fideicomiso" class="form-control" disabled></div>
					<label for="fideicomiso.estatus" class="col-form-label col-sm-2">Estatus</label>
					<div class="col-sm-4">
						<select v-model="garantia.fideicomiso.estatus" class="form-control" name="fideicomiso.estatus" id="fideicomiso.estatus" disabled>
							<option value="1">Activo</option>
							<option value="0">Inactivo</option>
							<option value="2">Caducado</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="fideicomiso.descripcion" class="col-form-label col-sm-2">Descripción</label>
					<div class="col-sm-10"><input v-model="garantia.fideicomiso.descripcion" type="text" name="fideicomiso.descripcion" id="fideicomiso.descripcion" class="form-control" disabled></div>
				</div>
			</fieldset>


			<fieldset v-show="tabs_info == 2">
				<legend>Partes</legend>
				
				<ul class="nav nav-tabs mb-3">
					<li v-for="(usuario, index) in usuarios" :key="usuario.id" class="nav-item"><span :class="'nav-link'+(usuario_index == index ? ' active' : '')" @click="usuario_index = index">{{ usuario.nombre.substr(0,10)+'...' }}</span></li>
				</ul>

				<div class="row form-group">
					<h4 class="col-sm-1">Nivel:</h4>
					<div class="col-sm-11">{{ usuario.nivel }}</div>
				</div>
				<div class="row form-group">
					<label for="fideicomisario.nombre" class="col-form-label col-sm-2">Nombre</label>
					<div class="col-sm-4"><input :value="usuario.nombre" type="text" name="fideicomisario.nombre" :id="'fideicomisario.nombre'" class="form-control" disabled></div>
					<label for="fideicomisario.rfc'" class="col-form-label col-sm-2">RFC</label>
					<div class="col-sm-4"><input :value="usuario.rfc" type="text" name="fideicomisario.rfc" :id="'fideicomisario.rfc'" class="form-control" disabled></div>
				</div>
				<div class="row form-group">
					<label for="fideicomisario.empresa" class="col-form-label col-sm-2">Empresa</label>
					<div class="col-sm-4"><input :value="usuario.empresa" type="text" name="fideicomisario.empresa" :id="'fideicomisario.empresa'" class="form-control" disabled></div>
					<label for="fideicomisario.nacionalidad'" class="col-form-label col-sm-2">Nacionalidad</label>
					<div class="col-sm-4"><input :value="usuario.nacionalidad" type="text" name="fideicomisario.nacionalidad" :id="'fideicomisario.nacionalidad'" class="form-control" disabled></div>
				</div>
				<div class="row form-group">
					<label for="fideicomisario.persona_peps" class="col-form-label col-sm-2">Persona PEPS</label>
					<div class="col-sm-4">
						<VSwitch v-model="usuario.persona_peps" textoff="No" texton="Sí" name="fideicomisario.persona_peps" id="fideicomisario.persona_peps" disabled/>
					</div>
					<label for="fideicomisario.persona_alto_riesgo" class="col-form-label col-sm-2">Persona alto riesgo</label>
					<div class="col-sm-4">
						<VSwitch v-model="usuario.persona_ato_riesgo" textoff="No" texton="Sí" name="fideicomisario.persona_alto_riesgo" id="fideicomisario.persona_alto_riesgo" disabled/>
					</div>
				</div>
			</fieldset>
			
			<fieldset v-show="tabs_info == 3">
				<legend>Dirección de la garantia</legend>

				<div class="row form-group">
					<label for="garantia.direccion.calle" class="col-form-label col-sm-2">Calle</label>
					<div class="col-sm-10"><input v-model="garantia.calle" type="text" name="garantia.direccion.calle" id="garantia.direccion.calle" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="garantia.direccion.no_interior" class="col-form-label col-sm-2">No interior</label>
					<div class="col-sm-4"><input v-model="garantia.no_interior" type="text" name="garantia.direccion.no_interior" id="garantia.direccion.no_interior" class="form-control"></div>
					<label for="garantia.direccion.no_exterior" class="col-form-label col-sm-2">No Exterior</label>
					<div class="col-sm-4"><input v-model="garantia.no_exterior" type="text" name="garantia.direccion.no_exterior" id="garantia.direccion.no_exterior" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="garantia.direccion.colonia" class="col-form-label col-sm-2">Colonia</label>
					<div class="col-sm-4"><input v-model="garantia.colonia" type="text" name="garantia.direccion.colonia" id="garantia.direccion.colonia" class="form-control"></div>
					<label for="garantia.direccion.delegacion" class="col-form-label col-sm-2">Delegación</label>
					<div class="col-sm-4"><input v-model="garantia.delegacion_municipio" type="text" name="garantia.direccion.delegacion" id="garantia.direccion.delegacion" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="garantia.direccion.estado" class="col-form-label col-sm-2">Estado</label>
					<div class="col-sm-4">
						<select v-model="garantia.estado" name="garantia.direccion.estado" id="garantia.direccion.estado" class="form-control">
							<option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
						</select>
					</div>
					<label for="garantia.direccion.cp" class="col-form-label col-sm-2">CP</label>
					<div class="col-sm-4"><input v-model="garantia.cp" type="text" name="garantia.direccion.cp" id="garantia.direccion.cp" class="form-control"></div>
				</div>
			</fieldset>

			<div v-show="tabs_info == 4" class="row">
				<div class="col-sm-8">
					<fieldset>
						<legend>Detalles</legend>

						<div class="row form-group">
							<label for="garantia.descripcion" class="col-form-label col-sm-2">Descripción</label>
							<div class="col-sm-10"><input v-model="garantia.descripcion" type="text" name="garantia.descripcion" id="garantia.descripcion" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="garantia.titulo_nombre" class="col-form-label col-sm-2">Título / Nombre</label>
							<div class="col-sm-4"><input v-model="garantia.titulo_nombre" type="text" name="garantia.titulo_nombre" id="garantia.titulo_nombre" class="form-control"></div>
							<label for="garantia.precio" class="col-form-label col-sm-2">Precio de venta</label>
							<div class="col-sm-4"><InputCompuesto v-model="garantia.precio" label="$" name="garantia.precio" id="garantia.precio" :formats="['moneyFormat']"/></div>
						</div>
						<div class="row form-group">
							<label for="garantia.valor" class="col-form-label col-sm-2">Valor</label>
							<div class="col-sm-4"><InputCompuesto v-model="garantia.valor" label="$" name="garantia.valor" id="garantia.valor" :formats="['moneyFormat']" /></div>
							<label for="garantia.superficie" class="col-form-label col-sm-2">Superficie</label>
							<div class="col-sm-4"><InputCompuesto v-model="garantia.superficie" label_right="m2" name="garantia.superficie" id="garantia.superficie" /></div>
						</div>
						<div class="row form-group">
							<label for="garantia.valuacion_m2" class="col-form-label col-sm-2">Valuación m2</label>
							<div class="col-sm-4"><InputCompuesto v-model="garantia.valuacion_m2" label="$" name="garantia.valuacion_m2" id="garantia.valuacion_m2" :formats="['moneyFormat']" /></div>
							<label for="garantia.valuacion" class="col-form-label col-sm-2">Valuación</label>
							<div class="col-sm-4"><InputCompuesto v-model="garantia.valuacion_m2" label="$" name="garantia.valuacion" id="garantia.valuacion" :formats="['moneyFormat']" /></div>
						</div>
						<div class="row form-group">
							<label for="garantia.superficie_construida" class="col-form-label col-sm-2">Superficie construida</label>
							<div class="col-sm-4"><InputCompuesto v-model="garantia.superficie_construida" label_right="m2" name="garantia.superficie_construida" id="garantia.superficie_construida" /></div>
							<label for="garantia.antiguedad" class="col-form-label col-sm-2">Antiguedad</label>
							<div class="col-sm-4"><InputCompuesto v-model="garantia.antiguedad" type="number" label_right="años" name="garantia.antiguedad" id="garantia.antiguedad" /></div>
						</div>
						<div class="row form-group">
							<label for="garantia.en_garantia" class="col-form-label col-sm-2">En garantia</label>
							<div class="col-sm-4">
								<select v-model="garantia.en_garantia" name="garantia.en_garantia" id="garantia.en_garantia" class="form-control">
									<option value="1">Sí</option>
									<option value="0">No</option>
								</select>
							</div>
							<label for="garantia.numero_escrituras" class="col-form-label col-sm-2">Número de escrituras</label>
							<div class="col-sm-4"><input v-model="garantia.numero_escrituras" type="text" name="garantia.numero_escrituras" id="garantia.numero_escrituras" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="garantia.fecha_escrituras" class="col-form-label col-sm-2">Fecha de escrituras</label>
							<div class="col-sm-4"><input v-model="garantia.fecha_escrituras" type="date" name="garantia.fecha_escrituras" id="garantia.fecha_escrituras" class="form-control"></div>
							<label for="garantia.notario" class="col-form-label col-sm-2">Notario</label>
							<div class="col-sm-4"><input v-model="garantia.notario" type="text" name="garantia.notario" id="garantia.notario" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="garantia.fecha_registro" class="col-form-label col-sm-2">Fecha de registro</label>
							<div class="col-sm-4"><input v-model="garantia.fecha_registro" type="date" name="garantia.fecha_registro" id="garantia.fecha_registro" class="form-control"></div>
							<label for="garantia.tipo_garantia" class="col-form-label col-sm-2">Tipo de garantia</label>
							<div class="col-sm-4">
								<select v-model="garantia.tipo_garantia" name="garantia.tipo_garantia" id="garantia.tipo_garantia" class="form-control">
									<option v-for="tipo_garantia in tipo_garantias" :key="tipo_garantia.id" :value="tipo_garantia.valor">{{ tipo_garantia.nombre }}</option>
								</select>
							</div>
						</div>
						<div class="row form-group">
							<label for="garantia.propia" class="col-form-label col-sm-2">Garantia propia</label>
							<div class="col-sm-4">
								<select v-model="garantia.propia" name="garantia.propia" id="garantia.propia" class="form-control">
									<option value="">Sí</option>
									<option value="">No</option>
								</select>
							</div>
							<label for="garantia.existencia_gravamen" class="col-form-label col-sm-2">Existencia gravamen</label>
							<div class="col-sm-4"><input v-model="garantia.existencia_gravamen" type="text" name="garantia.existencia_gravamen" id="garantia.existencia_gravamen" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="garantia.latitud" class="col-form-label col-sm-2">Latitud</label>
							<div class="col-sm-4"><input v-model="garantia.latitud" type="text" name="garantia.latitud" id="garantia.latitud" class="form-control"></div>
							<label for="garantia.longitud" class="col-form-label col-sm-2">Longitud</label>
							<div class="col-sm-4"><input v-model="garantia.longitud" type="text" name="garantia.longitud" id="garantia.longitud" class="form-control"></div>
						</div>
					</fieldset>
				</div>
				<div class="col-sm-4">
					<fieldset class="fieldset-map">
						<legend>Mapa</legend>
						<div id="mapa"></div>
					</fieldset>
				</div>
			</div>

			<div v-show="tabs_info == 5" class="row form-group">
				<div class="col-sm-6">
					<fieldset>
						<legend>Habitaciones y servicios</legend>

						<div class="row form-group">
							<label for="garantia.recamaras" class="col-form-label col-sm-2">Recamaras</label>
							<div class="col-sm-4"><input v-model="garantia.recamaras" type="number" name="garantia.recamaras" id="garantia.recamaras" class="form-control"></div>
							<label for="garantia.banos" class="col-form-label col-sm-2">Baños</label>
							<div class="col-sm-4"><input v-model="garantia.banos" type="number" name="garantia.banos" id="garantia.banos" class="form-control"></div>
						</div>
						<div class="row form-group">
							<label for="garantia.medios_banos" class="col-form-label col-sm-2">Medios baños</label>
							<div class="col-sm-4"><input v-model="garantia.medios_banos" type="number" name="garantia.medios_banos" id="garantia.medios_banos" class="form-control"></div>
							<label for="garantia.estacionamientos" class="col-form-label col-sm-2">Estacionamientos</label>
							<div class="col-sm-4"><input  v-model="garantia.estacionamientos" type="number" name="garantia.estacionamientos" id="garantia.estacionamientos" class="form-control"></div>
						</div>
					</fieldset>
				</div>
				<div class="col-sm-6">
					<fieldset>
						<legend>Amenidades</legend>
						<div class="row form-group">
							<div v-for="amenidad in amenidades" class="col-sm-4">
								<VCheckbox v-model="amenidades_seleccionadas" :label="amenidad.nombre" :value="amenidad.nombre" name="garantia_amenidades[]" :id="'garantia_amenidades_'+amenidad.valor" />
							</div>
						</div>
					</fieldset>
				</div>
			</div>

			<div v-if="tabs_info==6" class="row form-group">
				<Imagen :garantia="garantia" @update="obtener_garantia" @update_info="actualizar_solicitud_garantia"/>
			</div>

			<div v-if="tabs_info==7" class="row form-group">
				<Mensaje :garantia="garantia" />
			</div>
		</div>
		<div class="footer">
			<div class="row">
				<div class="col-sm-2 offset-sm-6"><button v-if="$auth.can('publicar_garantia_portal','administrador_de_garantias')" :class="'btn '+(garantia.estatus == 1 ? 'warning-btn' : 'principal-btn')" @click="estatus_garantia">{{ garantia.estatus == 1 ? 'Ocultar garantia' : 'Publicar garantia' }}</button></div>
				<div class="col-sm-2"><button class="btn secondary-btn" @click="guardar_garantia">Guardar</button></div>
				<div class="col-sm-2"><button class="btn danger-btn" @click="cerrar_modal(true)">Cerrar</button></div>
			</div>
		</div>
	</Modal>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import InputCompuesto from '@/components/Form/InputCompuesto'
	import VSwitch from '@/components/Form/Switch'
	import VCheckbox from '@/components/Form/Checkbox'

	import api from '@/apps/garantias/api/portal'
	import apiFideicomisos from '@/apps/garantias/api/fideicomisos'
	import apiUsuarios from '@/apps/garantias/api/usuarios'
	import apiOtros from '@/apps/garantias/api/otros'

	import gmaps from '@/helpers/gmaps'

	import Imagen from './Imagen';
	import Mensaje from './Mensaje';

	import config from '@/apps/garantias/config'

	export default {
		components: {
			Modal, InputCompuesto, VSwitch, VCheckbox, Imagen, Mensaje
		}
		,props: {
			garantia_id: 0
		}
		,data: function() {
			return {
				amenidades: []
				,garantia: {
					fideicomiso: {
						no_fideicomiso: null
						,descripcion: null
						,estatus: null
					}
					,calle: null
					,no_interior: null
					,no_exterior: null
					,colonia: null
					,delegacion: null
					,estado: null
					,cp: null
					,descripcion: null
					,valor: null
					,superficie: null
					,valuacion_m2: null
					,valuacion: null
					,superficie_construida: null
					,antiguedad: null
					,en_garantia: null
					,numero_escrituras: null
					,fecha_escrituras: null
					,notario: null
					,fecha_registro: null
					,tipo_garantia: null
					,propia: null
					,existencia_gravamen: null
					,latitud: null
					,longitud: null
					,recamaras: null
					,banos: null
					,medios_banos: null
					,estacionamientos: null
					,usuarios: []
					,imagenes: []
				}
				,usuario_index: 0
				,tabs_info: 1
				,load_map: false
				,google: null
				,usuarios: []
				,usuarios_procesados: false
				,garantia_org: null
				,amenidades_seleccionadas: []
				,tipo_garantias: []
				,estados: []
			}
		}
		,mounted: async function() {
			this.obtener_catalogo();
			this.obtener_garantia();
			this.$log.info('config', config);
		}
		,methods: {
			obtener_catalogo: async function() {
				try {
					if (!config.catalogos) {
						setTimeout(() => { this.obtener_catalogo()}, 500);
						return false;
					}

					this.amenidades = (await apiOtros.obtener_catalogo(config.catalogos.amenidades)).data.opciones;
					this.tipo_garantias = (await apiOtros.obtener_catalogo(config.catalogos.tipo_garantias)).data.opciones;
					this.estados = (await apiOtros.obtener_catalogo(config.catalogos.estados_validos_activos)).data.opciones;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,obtener_garantia: async function() {
				try {
					this.garantia = (await api.obtener_garantia(this.garantia_id)).data;
					this.garantia_org = JSON.stringify(this.garantia);
					this.garantia.fideicomiso = (await apiFideicomisos.obtener_fideicomiso(this.garantia.fideicomiso_id)).data;

					this.amenidades_seleccionadas = [];
					this.garantia.amenidades.forEach(amenidad => {
						this.amenidades_seleccionadas.push(amenidad.amenidad);
					})
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cerrar_modal: function(descartar) {
				if (typeof descartar == 'undefined')
					descartar = false;

				if (!descartar) {
					let garantia = JSON.stringify(this.garantia)

					if (garantia != this.garantia_org)
						return this.$helper.showMessage('Error','Tienes cambios sin guardar','error','alert');
				}

				this.$emit('close');
			}
			,guardar_garantia: async function() {
				this.$log.info(this.garantia);
				try {
					let amenidades_org = this.$helper.clone(this.garantia.amenidades);
					this.garantia.amenidades = [];

					this.amenidades_seleccionadas.forEach(amenidad => {
						let id = null;
						for(let i=0; i<amenidades_org.length; i++) {
							if (amenidad == amenidades_org[i].amenidad) {
								id = amenidades_org[i].id;
								i = amenidades_org.length;
							}
						}

						if (id === null)
							this.garantia.amenidades.push({garantia_id: this.garantia.id, amenidad: amenidad});
						else
							this.garantia.amenidades.push({id, garantia_id: this.garantia.id, amenidad: amenidad});
					});

					this.garantia = (await api.guardar_garantia(this.garantia.id, this.garantia)).data;
					this.garantia_org = JSON.stringify(this.garantia);
					this.garantia.fideicomiso = (await apiFideicomisos.obtener_fideicomiso(this.garantia.fideicomiso_id)).data;

					this.amenidades_seleccionadas = [];
					this.garantia.amenidades.forEach(amenidad => {
						this.amenidades_seleccionadas.push(amenidad.amenidad);
					})

					this.$helper.showMessage('Garantia guadada','La garantia se guardo con exito','success');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,estatus_garantia: async function() {
				try {
					this.garantia = (await api.guardar_garantia(this.garantia.id, {estatus: (this.garantia.estatus == 1 ? 0 : 1)})).data;
					this.garantia_org = JSON.stringify(this.garantia);
					this.garantia.fideicomiso = (await apiFideicomisos.obtener_fideicomiso(this.garantia.fideicomiso_id)).data;

					this.amenidades_seleccionadas = [];
					this.garantia.amenidades.forEach(amenidad => {
						this.amenidades_seleccionadas.push(amenidad.amenidad);
					})

					this.$emit('update');
					this.$helper.showMessage('Garantia guadada','La garantia se '+(this.garantia.estatus == 1 ? 'publicado' : 'ocultado')+' la garantia con exito','success');
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,actualizar_solicitud_garantia: function(info_garantia) {
				this.garantia.solicitud_id = parseInt(info_garantia.solicitud_id);
				this.garantia.garantia_id = parseInt(info_garantia.garantia_id);
				this.$log.info('garantia', this.garantia);

				this.guardar_garantia();
			}
		}
		,computed: {
			usuario: function() {
				if (this.garantia && this.usuarios_procesados && typeof this.usuarios[this.usuario_index] != 'undefined') {
					return this.usuarios[this.usuario_index];
				}else
					return {
						nivel: null
						,nombre: null
						,rfc: null
						,empresa: null
						,nacionalidad: null
						,persona_peps: 0
						,persona_alto_riesgo: 0
					}
			}
		}
		,watch: {
			garantia: async function() {
				if (this.garantia.usuarios && this.garantia.usuarios.length > 0 && !this.usuarios_procesados) {
					this.usuarios = [];
					this.garantia.usuarios.forEach(async usuario => {
						let tmp = (await apiUsuarios.obtener_parte(usuario.usuario_id)).data;
						tmp.nivel = usuario.nivel;
						this.usuarios.push(tmp);
					})

					this.usuarios_procesados = true;
				}

				if (this.tabs_info == 4) {
					if (!this.google) {
						this.$log.info('Cargamos google');
						this.google = await gmaps();
					}

					if (this.garantia.latitud && this.garantia.longitud) {
					    this.map = new this.google.maps.Map(document.getElementById('mapa'), {
					      center: {
					        lat: parseFloat(this.garantia.latitud),
					        lng: parseFloat(this.garantia.longitud)
					      }
					      ,zoom: 17
					    })

					    let marker = new this.google.maps.Marker({
					      position: new this.google.maps.LatLng(parseFloat(this.garantia.latitud), parseFloat(this.garantia.longitud)),
					      map: this.map,
					      title: 'Garantia'
					    });
					}
				}
			}
			,tabs_info: async function(val) {
				if (val == 4) {
					if (!this.google) {
						this.$log.info('Cargamos google');
						this.google = await gmaps();
					}

					if (this.garantia.latitud && this.garantia.longitud) {
					    this.map = new this.google.maps.Map(document.getElementById('mapa'), {
					      center: {
					        lat: parseFloat(this.garantia.latitud),
					        lng: parseFloat(this.garantia.longitud)
					      }
					      ,zoom: 17
					    })

					    let marker = new this.google.maps.Marker({
					      position: new this.google.maps.LatLng(parseFloat(this.garantia.latitud), parseFloat(this.garantia.longitud)),
					      map: this.map,
					      title: 'Garantia'
					    });
					}
				}
			}
		}
	}
</script>

<style lang="scss">
	fieldset {
		margin-bottom: 20px;
	}

	.subseccion {
		background-color: #333333;
		color: #fff;
		font-weight: bold;
		font-size: 1.3em;
		padding: 20px 30px;
	}

	.fieldset-map {
		width: 100%;
		height: 100%;
		overflow: auto;

		#mapa {
			width: 100%;
			height: 100%;
		}
	}

	.nav-link {
		cursor: pointer;
	}

	.active {
		background-color: #E6E6E6 !important;
	}
</style>