let config = {}

switch(process.env.VUE_APP_ENV) {
	case 'local':
		config.catalogos = {
			amenidades: 1
			,tipo_garantias: 2
			,estados: 3
			,estados_validos_activos: 3
		}
		break;
	case 'test':
		config.catalogos = {
			amenidades: 1
			,tipo_garantias: 2
			,estados: 3
			,estados_validos_activos: 6
		}
		break;
	case 'production':
		config.catalogos = {
			amenidades: 1
			,tipo_garantias: 2
			,estados: 3
			,estados_validos_activos: 6
		}
		break;
}

export default config;