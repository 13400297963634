import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_GARANTIAS);

class Garantias {
	obtener_fideicomisos(options) {
		return http.get(utils.url_options(`/fideicomisos`,options));
	}

	obtener_fideicomisos_tipo(tipo, options) {
		if (typeof tipo == 'undefined')
			tipo = 1;
		
		return http.get(utils.url_options(`/fideicomisos/tipo/${tipo}`,options));
	}

	obtener_fideicomiso(id) {
		return http.get(`/fideicomisos/${id}`);
	}

	crear_fideicomiso(payload) {
		return http.post('/fideicomisos', payload);
	}

	editar_fideicomiso(id, payload) {
		return http.put(`/fideicomisos/${id}`, payload);
	}

	ver_fideicomiso(id) {
		return http.get(`/fideicomisos/${id}`);
	}

	asignar_patrimonio(fideicomiso, patrimonio) {
		return http.put(`/fideicomisos/${fideicomiso}/patrimonio/${patrimonio}`);
	}

	quitar_patrimonio(fideicomiso, patrimonio) {
		return http.delete(`/fideicomisos/${fideicomiso}/patrimonio/${patrimonio}`);
	}

	asignar_usuario(fideicomiso, tipo, usuario, nivel) {
		return http.put(`/fideicomisos/${fideicomiso}/usuario/${usuario}/tipo/${tipo}/nivel/${nivel}`);
	}

	quitar_usuario(fideicomiso, usuario, tipo) {
		return http.delete(`/fideicomisos/${fideicomiso}/usuario/${usuario}/tipo/${tipo}`);
	}
}

export default new Garantias();